import React, { memo } from 'react'
import Styles from '../../../Css/View360/View360.module.css'

const BeforeContainer = ({ showhotspotBeforeAfter, video360Urls, IsVdp, skuId,state,handleMouseDown,handleMouseUp,handleTouchStarted,touchEnd,isImageLoaded }) => {
    // const queryParams = new URLSearchParams(window.location.search);
    // const skuId = queryParams.get('sku_id');
    return (
        <div className={Styles[showhotspotBeforeAfter ? "grid-view" : "min-list-view"]}>
            <div className={Styles["before-image"]}>
                <div className={Styles['beforeImg']}>
                    {IsVdp ?
                    //  <iframe
                    //     width="100%"
                    //     height="100%"
                    //     id='iframe-vdp'
                    //     title="360_exterior"
                    //     src={`https://assets.spyne.ai/360?sku_id=${skuId}&type=input&image_category=360_exterior&hotspot=false`}
                    //     style={{
                    //         outline: "none",
                    //         border: "none",
                    //         minHeight: "255px"

                    //     }}
                    // />
                        <img
                        src={state.images[state.imageIndex]?.inputUrl}
                        alt={state.images[state.imageIndex]?.imageName || ''}
                        id={state.images[state.imageIndex]?.imageId}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onTouchStart={(e) => handleTouchStarted(e)}
                        onTouchEnd={touchEnd}
                        draggable="false"
                        onLoad={isImageLoaded}
                        fetchpriority="high"
                        className='cursor-pointer z-10 w-[inherit]'
                    />
                    : <video controls className={Styles['video-container']}>
                        <source src={video360Urls[0]?.lres_video_url || video360Urls[0]?.video_url} type="video/mp4" className={Styles['frame_video']} />
                    </video>}
                </div>
            </div>
        </div>
    )
}

export default memo(BeforeContainer)