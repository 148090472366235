import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'

const MobileViewHeadingContainer = ({beforAfterView,video360Urls, IsVdp, skuId,state,handleMouseDown,handleMouseUp,handleTouchStarted,touchEnd,isImageLoaded }) => {   
     return (
        <div className={Styles["mobile-video-uploader"]}>

            {beforAfterView ?
                <div className={Styles['slider-container']}>
                    <div className={Styles['image-slider']}>

                        <div className={Styles['image-slider-header']}>
                            <div className={Styles["mobile-input-output-container"]}>
                                <p className={Styles['before-after-text']}>Before</p>
                            </div>
                        </div>
                    </div>
                </div> : null}
            <div className={Styles["before-image"]}>
                <div className={Styles['beforeImg']}>
                    <div className={Styles['video-container']}>
                    {IsVdp ?
                    //  <iframe
                    //     width="100%"
                    //     height="100%"
                    //     id='iframe-vdp'
                    //     title="360_exterior"
                    //     src={`https://assets.spyne.ai/360?sku_id=${skuId}&type=input&image_category=360_exterior&hotspot=false`}
                    //     style={{
                    //         outline: "none",
                    //         border: "none",
                    //         minHeight: "255px"

                    //     }}
                    // />
                        <img
                        src={state.images[state.imageIndex]?.inputUrl}
                        alt={state.images[state.imageIndex]?.imageName || ''}
                        id={state.images[state.imageIndex]?.imageId}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onTouchStart={(e) => handleTouchStarted(e)}
                        onTouchEnd={touchEnd}
                        draggable="false"
                        onLoad={isImageLoaded}
                        fetchpriority="high"
                        className='cursor-pointer z-10'
                    />
                    : <video controls className={Styles['video-container']}>
                        <source src={video360Urls[0]?.lres_video_url || video360Urls[0]?.video_url} type="video/mp4" className={Styles['frame_video']} />
                    </video>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MobileViewHeadingContainer
