import React, { Suspense, lazy, useEffect, useState } from "react";
import Tabs from "../Components/common/Tabs";
import Loader from "../Components/common/Loader";
import { ASSETS, IFRAME_MODULE_CONFIG, TAB_ENUM, TAB_OPTIONS } from "../Components/common/config";
import { getDynamicTab, getDynamicTabImage, getMediaDynamicTab } from "../utils/getDynamicTab";
import centralAPI from "../CentralAPIHandler/CentralAPIHandler";
import { getParams } from "../utils/getParams";
import { transformedSku360Data } from "../utils/transformAPIData";
import { IMAGE_COMPRESSOR } from "../utils/imageCompressor";

const Spin = lazy(() => import("../Components/View360/View360"));
const View360Common = lazy(() => import("../Components/View360/View360Common"));
const Video = lazy(() => import("../Components/Video/Video"));
const Gallery = lazy(() => import("../Components/gallery/Gallery"));


const CommonIframeHome = () => {
  
  const [skuData, setSkuData] = useState({});
  const [_360Data, set360Data] = useState({});
  const [loading, setLoading] = useState(true);
  const [dynamicTab, setDynamicTab] = useState([]);
  const [dynamicTabImage, setDynamicTabImage] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TAB_ENUM.THREE_SIXTY_VIEW);
  const {params} = getParams()

  const fetchApidata = async () => {
    try {
      setLoading(true)
      const response = await centralAPI.handleGetRequest(`${process.env.REACT_APP_BASEURL_API_SPYNE}/api/nv1/hotspot/sku-data`, params); 
      localStorage.setItem('enterprise id' , response?.data.enterprise_id);
      setSkuData(response?.data)
      set360Data(transformedSku360Data(response?.data))
      const getTabBasedOnConfig = getDynamicTab(response?.data)
      setDynamicTab(getTabBasedOnConfig);
      setDynamicTabImage(getDynamicTabImage(response?.data?.image));
      setLoading(false)
      if (getTabBasedOnConfig.length > 0) {
        setSelectedTab(getTabBasedOnConfig[0].key);
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  const fetchMediaFromStorage = async ({mediaId, product}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MEDIA_BUCKET_URL}/m/o/${mediaId}/${product}.json`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching media data:', error);
      return null;
    }
  }
  const fetchMediaData = async ({mediaId, spin=0, catalog=0, feature_video=0}) => {
    try {
      if(!mediaId) return;
      if(!spin && !catalog && !feature_video) return;
      const promise = []
      const productsPicked = []
      if(spin) {
        promise.push(fetchMediaFromStorage({mediaId, product: 'spin'}))
        productsPicked.push('360')
      }
      if(catalog) {
        promise.push(fetchMediaFromStorage({mediaId, product: 'catalog'}))
        productsPicked.push('image')
      }
      if(feature_video) {
        promise.push(fetchMediaFromStorage({mediaId, product: 'feature_video'}))
        productsPicked.push('video')
      }
      let isMediaPresent = false;
      const data = await Promise.all(promise)
      const mediaData = {
        '360':null,
        'image':null,
        'video':null
      }

      const teamConfig = {
        "360": {
          "isActive": spin ? true : false,
          "position": spin
        },
        "image": {
          "isActive": catalog ? true : false,
          "position": catalog
        },
        "video": {
          "isActive": feature_video ? true : false,
          "position": feature_video
        }
      }
      setLoading(false)
      for(let i=0; i<data.length; i++) {
        if(data[i]){
          isMediaPresent = true;
          if(productsPicked[i] === '360') {
            mediaData['360'] = data[i]
          }else if(productsPicked[i] === 'image') {
            mediaData['image'] = data[i].imageData
          }else if(productsPicked[i] === 'video') {
            mediaData['video'] = data[i]
            // @todo: remove this fixed iframe url after testing 
            mediaData['video'].iFrameUrl = `${process.env.REACT_APP_VIDEO_IFRAME_DOMAIN}/video?video_id=${data[i].video_id}&notVerified`
          }
        }
      }
      mediaData['team_config'] = teamConfig
      mediaData['isMediaPresent'] = isMediaPresent;
      const getTabBasedOnConfig = getMediaDynamicTab(mediaData)
      setDynamicTab(getTabBasedOnConfig);
      if(mediaData.image){
        setDynamicTabImage(getDynamicTabImage(mediaData.image));
      }
      setSkuData(mediaData)
      if (getTabBasedOnConfig.length > 0) {
        setSelectedTab(getTabBasedOnConfig[0].key);
      }
    } catch (error) {
      console.error('Error fetching media data:', error);
    }
  }

  useEffect(() => {
    if(params?.mediaId) {
      fetchMediaData({'mediaId': params?.mediaId, 'spin': Number(params?.spin), 'catalog': Number(params?.catalog), 'feature_video': Number(params?.feature_video)})
    }else{
      fetchApidata();
    }
  }, []);

  if(!params?.mediaId) {
    return (
      <div className=" flex flex-col items-center justify-center h-full w-full transition-all duration-700 ease-in">
        <div className=" relative border rounded-md h-full w-full ">
          {(!loading && (skuData?.status?.toLowerCase() !== IFRAME_MODULE_CONFIG.isSkuDone)) ? 
            <img className="w-full h-full max-sm:object-contain" src={IMAGE_COMPRESSOR({url:ASSETS.other.noSkuDone})} alt="vin is in process"/>
            :
            <>
          {loading ? 
          <Loader/>
          :
            <Suspense fallback={<Loader showPageName={selectedTab} />}>
            {selectedTab === TAB_ENUM.THREE_SIXTY_VIEW ? (
              <View360Common data={_360Data}/>
            ) : selectedTab === TAB_ENUM.VIDEO_VIEW ? (
              <Video videoUrl={skuData?.video} tabLength={dynamicTab?.length}/>
            ) : selectedTab === TAB_ENUM.GALLERY_VIEW ? (
              <Gallery galleryData={Object.values(skuData?.image).flat()} tabLength={dynamicTab?.length} dynamicTabImage={dynamicTabImage}/>
            ) : null}
          </Suspense>}
        {(dynamicTab?.length > 1) && <div className={` fixed bottom-0 ${selectedTab === TAB_ENUM.VIDEO_VIEW ? "md:bottom-[10%]" : "md:bottom-[4.2%]"}  left-0 md:left-12 bg-blue-lightestBlue shadow-md shadow-black-8 sm:rounded-b-md md:rounded-2xl max-md:w-full md:w-max z-50`}>
            <Tabs
              options={dynamicTab}
              onSelect={(option) => setSelectedTab(option.key)}
            />
          </div>}
          </>
          }
        </div>
      </div>
    );
  }

  return (
    <div className=" flex flex-col items-center justify-center h-full w-full transition-all duration-700 ease-in">
      <div className=" relative border rounded-md h-full w-full ">
        {
          (!loading && (!skuData.isMediaPresent)) ? 
          <img className="w-full h-full max-sm:object-contain" src={IMAGE_COMPRESSOR({url:ASSETS.other.noSkuDone})} alt="vin is in process"/> 
          :
        <>
          {loading ? 
          <Loader/>
          :
            <Suspense fallback={<Loader showPageName={selectedTab} />}>
            {selectedTab === TAB_ENUM.THREE_SIXTY_VIEW ? (
              skuData?.['360'] && Object.keys(skuData?.['360']).length > 0 ? <Spin preloadedData={skuData?.['360']} /> : null
            ) : selectedTab === TAB_ENUM.VIDEO_VIEW ? (
              <Video iFrameUrl={skuData?.video?.iFrameUrl} tabLength={dynamicTab?.length}/>
            ) : selectedTab === TAB_ENUM.GALLERY_VIEW ? (
              <Gallery galleryData={Object.values(skuData?.image).flat()} tabLength={dynamicTab?.length} dynamicTabImage={dynamicTabImage}/>
            ) : null}
          </Suspense>}
        {(dynamicTab?.length > 1) && <div className={` fixed bottom-0 ${selectedTab === TAB_ENUM.VIDEO_VIEW ? "md:bottom-[10%]" : "md:bottom-[4.2%]"}  left-0 md:left-12 bg-blue-lightestBlue shadow-md shadow-black-8 sm:rounded-b-md md:rounded-2xl max-md:w-full md:w-max z-50`}>
            <Tabs
              options={dynamicTab}
              onSelect={(option) => setSelectedTab(option.key)}
            />
          </div>}      
        </>
        }
    </div>
    </div>
  )
};

export default CommonIframeHome;
