import React, { useState, useEffect, useCallback, useMemo } from 'react';

const AnimatedLoader = ({ backgroundColorClass = 'bg-transparent' }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const spin_motion = queryParams.get('spin_motion');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const mobileView = window.innerWidth < 470;
  const ANIMATION_DURATION = 80;
  const INTERVAL_DURATION = ANIMATION_DURATION;

  const keyframes = `
    @keyframes slideInUp {
      0% {
        transform: translateY(12%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes slideOutUp {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(-25%);
        opacity: 0;
      }
    }
  `;

  const svgs = useMemo(() => [
    { id: "1", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078482.svg", alt: "Loading 1" },
    { id: "2", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078477.svg", alt: "Loading 2" },
    { id: "3", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078476.svg", alt: "Loading 3" },
    { id: "4", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078475.svg", alt: "Loading 4" },
    { id: "5", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078473.svg", alt: "Loading 5" },
    { id: "6", src: "https://d20uiuzezo3er4.cloudfront.net/console/Frame+1984078470.svg", alt: "Loading 6" },
  ], []);

  useEffect(() => {
    svgs.forEach(svg => {
      const img = new Image();
      img.src = svg.src;
    });
  }, [svgs]);

  const handleAnimationEnd = useCallback(() => {
    setIsAnimating(false);
    setPreviousIndex(null);
  }, []);

  useEffect(() => {
    if (isAnimating) return;

    const interval = setInterval(() => {
      setIsAnimating(true);
      setPreviousIndex(currentIndex);
      setCurrentIndex(prevIndex => (prevIndex + 1) % svgs.length);
    }, INTERVAL_DURATION);

    return () => clearInterval(interval);
  }, [currentIndex, isAnimating, svgs.length]);

  // Conditional rendering to handle `spin_motion`
  if (spin_motion) {
    return null;
  }

  return (
    <>
      <style>{keyframes}</style>
      <div className="fixed inset-0 flex items-center justify-center min-h-screen overflow-hidden">
        <div
          className="relative flex flex-col items-center justify-center"
          style={{
            width: mobileView ? '50vw' : '32vw',
            height: mobileView ? '50vh' : '34vh',
          }}
        >
          {previousIndex !== null && isAnimating && (
            <div
              key={`prev-${svgs[previousIndex].id}`}
              className="absolute"
              style={{ animation: 'slideOutUp 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards' }}
              onAnimationEnd={handleAnimationEnd}
            >
              <img
                src={svgs[previousIndex].src}
                alt={svgs[previousIndex].alt}
                className="w-full h-auto object-contain"
                loading="eager"
              />
            </div>
          )}

          <div
            key={`current-${svgs[currentIndex].id}`}
            className="absolute"
            style={
              isAnimating
                ? { animation: 'slideInUp 500ms cubic-bezier(0.4, 0, 0.2, 0.5) forwards' }
                : {}
            }
          >
            <img
              src={svgs[currentIndex].src}
              alt={svgs[currentIndex].alt}
              className="w-full h-auto object-contain"
              loading="eager"
            />
          </div>

          <div
            className="absolute top-[120%]" >
            <span className="text-[10px] text-gray-600 sm:text-[12px] md:text-[14px]" 
              >
              Loading Spin Media...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedLoader;